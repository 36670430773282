<template>
  <v-container fluid class="class-body pa-10">

    <v-row>
      <v-col>
        <v-img :height="$vuetify.breakpoint.mdAndDown ? '90' : '140'" contain
          src="../../../assets/img/logo_rasmi_jmg.png"></v-img>

        <h1 class="text-center pt-4  white--text">
          MiQIMS
        </h1>

        <h1 class="text-center pa-1 pb-4  white--text"
          :style="$vuetify.breakpoint.mdAndDown ? 'font-size: 20px;' : 'font-size: 25px;'">
          Mines And Quarries Integrated Monitoring System
        </h1>

        <form @submit.prevent="onSignin" class="ma-0">

          <v-card class="class-vcard mx-auto py-5" elevation="4" width="350px" justify>

            <v-card-subtitle>
              <v-col cols="12">

                <v-text-field label="Email" name="Math.random()" solo v-model="email" dense class="field-login" required
                  autocomplete="email" :rules="emailRules"></v-text-field>

                <v-text-field label="Password" name="Math.random()" solo v-model="password" dense
                  :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]"
                  :type="passwordShow ? 'text' : 'password'" class="field-login"
                  @click:append="passwordShow = !passwordShow" autocomplete="new-password"></v-text-field>

                <v-card-text class="pl-1 py-0 buttonclr--text" @click="forgotPassDialog = !forgotPassDialog"
                  style="cursor:pointer;">
                  Forgot Password? Click here
                </v-card-text>
              </v-col>
            </v-card-subtitle>

            <center>
              <v-alert dense text type="error" color="error" width="80%" class="justify-center" outlined
                v-show="showLoginError">
                Wrong email/password
              </v-alert>
            </center>

            <v-card-actions class="justify-center px-7 mb-2">
              <v-btn elevation="2" class="white--text" width="100%" color="buttonclr" type="submit"
                :loading="loadingLogin">LOGIN
              </v-btn>
            </v-card-actions>

          </v-card>

        </form>

      </v-col>
    </v-row>

    <v-btn v-if="$vuetify.breakpoint.mdAndDown" elevation="2" fab small class="white--text ma-2 " color="buttonclr"
      style="position: absolute;bottom: 10px;right: 20px;">
      <v-icon dark right class="mr-2" @click="footerMobile = true">
        mdi-chevron-down
      </v-icon>
    </v-btn>

    <v-bottom-sheet v-model="footerMobile" scrollable>
      <v-sheet class="text-center white--text" height="230px" style="background-color: #0081BA;">

        <v-container>
          <div class="py-3" style="text-align: left;">

            For any enquiries, contact email at :<br> <a href="https://eqmp.scienotech.com.my/" target="_blank"
              class="white--text footerlink">admin@scienotech.com.my</a> <br><br>

            <v-icon class="mr-2 white--text">mdi-circle-medium</v-icon> <a href="https://eqmp.scienotech.com.my/"
              target="_blank" class="white--text footerlink" style="text-decoration: none;">Privacy Policy </a>
            <v-icon class="mr-2 white--text">mdi-circle-medium</v-icon> <a href="https://eqmp.scienotech.com.my/"
              target="_blank" class="white--text footerlink" style="text-decoration: none;">User Manual </a> <br>
            <v-icon class="mr-2 white--text">mdi-circle-medium</v-icon> <a
              href="https://www.jmg.gov.my/en/penafian-dan-notis-hak-cipta" target="_blank" class="white--text footerlink"
              style="text-decoration: none;">Disclaimer and Copyright Notice </a> <br><br>

            Download App
            <v-icon color="white">mdi-android</v-icon>
            <v-icon color="white">mdi-apple</v-icon> ||

            Compatibility
            <v-icon color="white">mdi-google-chrome</v-icon>
            <v-icon color="white">mdi-firefox</v-icon>
            <v-icon color="white">mdi-microsoft-edge</v-icon>

          </div>
        </v-container>

      </v-sheet>
    </v-bottom-sheet>

    <v-snackbar v-model="snackbarMsg" :timeout="timeout" color="red">
      {{ errorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbarMsg = false">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </template>
    </v-snackbar>


    <v-row justify="center">
      <v-dialog v-model="forgotPassDialog" persistent :max-width="$vuetify.breakpoint.smAndUp ? '450px' : '90%'">
        <v-card>

          <v-card-title class="px-6 card-header white--text">
            <v-icon class="mr-3 white--text">mdi-lock-question</v-icon>
            <h3>Forgot Password</h3>
          </v-card-title>


          <v-card-text class="py-6">
            <v-row>
              <v-text-field v-model="reset_email" placeholder="Email" solo outlined dense append-icon="mdi-email"
                class="pa-0 ma-0" :error-messages="errMsgEmail"></v-text-field>
            </v-row>
            <v-row>
              <v-checkbox dense hide-details class="align-center text-xs-center pt-0" v-model="displayCaptcha"
                label="I'm not a robot" @click="getCaptcha()"></v-checkbox>
            </v-row>

            <v-row class="align-center  justify-center text-xs-center mt-5">
              <v-col cols=6 v-show="displayCaptcha == true">
                <v-img :src="captchaPic" width="200px"></v-img>
              </v-col>
              <v-col cols=2 v-show="displayCaptcha == true">
                <v-btn @click="getCaptcha" width="100%" class="primary--text"><v-icon>mdi-reload</v-icon></v-btn>
              </v-col>
            </v-row>
            <v-row class="align-center  justify-center text-xs-center mt-5">
              <v-col v-show="displayCaptcha == true">
                <v-text-field v-model="captchaString" dense outlined label="Enter Captcha"
                  :error-messages="errMsgCaptcha"></v-text-field>
              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions class="card-footer">
            <v-spacer></v-spacer>
            <v-btn color="error" @click="forgotPassDialog = false; displayCaptcha = false">
              CANCEL
            </v-btn>
            <v-btn color="button" class="white--text px-4 mx-3" @click="validateCaptcha();">
              SEND
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog v-model="dialogSuccesfullyResetPass" width="500">
      <v-card>
        <v-card-title class=" primary text-h5 white--text button card-header ">
          <v-icon class="white--text mr-2">mdi-lock-reset</v-icon>Reset Password
        </v-card-title>

        <v-card-text class="pa-5 d-flex justify-center">
          {{ successMsg }}
        </v-card-text>



        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogSuccessStationAdded = false; reloadPage()">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>

// -- import something here --
import axios from "axios";
import { bus } from '@/main';


export default {

  data: () => ({

    footerMobile: false,
    loadingLogin: false,
    showLoginError: false,
    email: "",
    password: "",
    passwordShow: false,
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail does not valid',
    ],
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => (`The email and password you entered don't match`),
    },
    absolute: true,
    overlay: false,

    snackbarMsg: false,
    errorMessage: `The email or password you entered don't match`,
    timeout: 10000,
    forgotPassDialog: false,
    robotCheckbox: false,


    //forgot password declaration 
    displayCaptcha: false,
    captchaPic: "",
    captchaString: "",
    reset_email: "",
    captchaID: "",
    dialogSuccesfullyResetPass: false,
    errMsgEmail: "",
    errMsgCaptcha: '',
    successMsg: '',



  }),

  mounted() {

    localStorage.clear()

  },

  methods: {

    onSignin() {
      this.loadingLogin = true;
      this.$store.dispatch("signUserIn", {
        email: this.email,
        password: this.password,
      });
    },

    getCaptcha() {

      axios
        .get(this.globalUrl + `mqm2/users/captcha`, {
          headers: {
            Authorization: "Bearer " + null,
          },
        })
        .then((response) => {
          this.captchaID = response.data.id;
          this.captchaPic = response.data.captchablob;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    validateCaptcha() {
      axios
        .get(this.globalUrl + "mqm2/users/resetpass?emel=" + this.reset_email + "&kapchaword=" + this.captchaString + "&kapchaid=" + this.captchaID,

          {
            headers: {
              Authorization: "Bearer " + null,
            },
          }
        )
        .then((response) => {

          this.errMsgCaptcha = "";
          this.errMsgEmail = "";

          this.successMsg = response.data.message;
          let indication = response.data.indication
          if (indication == "successful") {
            this.forgotPassDialog = false;
            this.dialogSuccesfullyResetPass = true;
          }
          else if (indication == "unsuccessful" && response.data.message == "Wrong captcha.")
            this.errMsgCaptcha = response.data.message;
          else if (indication == "unsuccessful" && response.data.message == "Your email doesn't exist in our record")
            this.errMsgEmail = response.data.message;


        })
        .catch((error) => {
          this.errorMsg = "Your email doesn't exist in our record";
          this.errorAlert = true;
          console.log(error);
        });
    },

  },

  created() {
    bus.$on('triggerSnackBar', () => {
      this.snackbarMsg = true;
      this.loadingLogin = false;
    })
  }

};
</script>

<style lang="scss">
@import '~scss/main';


#whole-page {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

.class-body {
  //background-image: url("~@/assets/miqims-login.jpg") !important;
  background-image: url("~@/assets/temp-BG.jpg") !important;
  background-size: cover !important;
  background-position: 50% 80%;
  overflow-y: hidden;
  // background-position: 50% 40%;
  // background-repeat: no-repeat;

  // background-color: white;
  // background-image: linear-gradient(to top, #0e429c, #ffffff) !important;
  //background-image: linear-gradient(to top, #0e429c, #ffffff) !important;

  height: 100%;
  position: relative;
}

.class-vcard {
  background-image: url("~@/assets/card.jpg") !important;
  background-size: cover !important;
  background-position: 50% 80%;
  overflow-y: hidden;
  height: 100%;
  position: relative;
}

.footerlink:hover {
  font-size: 15px;
  cursor: pointer;
}
</style>


